<template>
    <div>
        <span v-html="getContent(question.question_text)"></span>
        <div v-for="answer_option in question.answers" :key="answer_option.id">
            <input type="checkbox" :id="answer_option.id" :value="answer_option.text">
            <label class="ml-2" v-html="answer_option.text"></label>
        </div>
        <div style="margin-top:10px">
            <div style="color:green; font-weight:bold">Correct Answer:</div>
            <div v-for="(answer_fact, d) in getCorrectAnswersForMultiple(question)" :key="d">
                <div>{{d+1}}. <span v-html="answer_fact.answer_text"></span></div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'multiple-answers-question',
    props:{
        question: {
            type: Object,
            required: true,
            default: function () {
                return { id: null }
            }
        },
    },
    watch:{
     question: 
        {
            handler(val){
            // do stuff
                //console.log(val)
            },
            deep: true
        }
    },
    mounted(){
        //console.log(this.question)
    },
    methods:{}

}
</script>
<template>
    <div>
        <span v-html="getContent(question.question_text)"></span>
        <div class="row" :key="answer_option.id" v-for="(answer_option, i) in question.answers" style="border-top: 1px solid #d8d8d8;fonts-size:10px;">
            <div class="col-sm-6">
            {{answer_option.text}}
            </div>
            <div class="col-sm-6">
                <CSelect class="mt-2 mb-2" style="height:50px;" :options="getmatchingAnswerOptions(question)">
                </CSelect>
            </div>
        </div>
        <div style="color:green; font-weight:bold">Correct Answer:</div>
        <div v-for="(answer_fact, d) in question.answers" :key="d">
            <div>{{d+1}}. <span v-html="answer_fact.answer_match_right"></span></div>
        </div>
    </div>
</template>
<script>
export default {
    name:'matching-question',
    props:{
        question: {
            type: Object,
            required: true,
            default: function () {
                return { id: null }
            }
        },
    },
    watch:{
     question: 
        {
            handler(val){
            // do stuff
                //console.log(val)
            },
            deep: true
        }
    },
    mounted(){
        //console.log(this.question)
    },
    methods:{

    }

}
</script>
<template>
    <div>
        <div v-html="getContentForFillMultipleBlanks(question)"></div>
    </div>
</template>
<script>
export default {
    name:'fill-in-multiple-blanks-question',
    props:{
        question: {
            type: Object,
            required: true,
            default: function () {
                return { id: null }
            }
        },
    },
    watch:{
     question: 
        {
            handler(val){
            // do stuff
                //console.log(val)
            },
            deep: true
        }
    },
    mounted(){
        //console.log(this.question)
    },
    methods:{

    }

}
</script>
<template>
    <div>
        <span v-html="getContent(question.question_text)"></span>
        <div class="mt-4">
            <input type="text" name="fill_in_blank" style="max-width: 180px; height:25px">
        </div>
        <div style="color:green; font-weight:bold">Correct Answer:</div>
        <div v-for="(answer_option, k) in question.answers" :key="k">
            <div v-if="answer_option.numerical_answer_type == 'range_answer'">
                <b>Answer in the range:</b> between {{answer_option.starting_range}}  and {{answer_option.ending_range}}
            </div>
            <div v-if="answer_option.numerical_answer_type == 'exact_answer'">
                <b>Exact Answer:</b> {{answer_option.exact}}  with error margin {{answer_option.margin}}
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'numerical',
    props:{
        question: {
            type: Object,
            required: true,
            default: function () {
                return { id: null }
            }
        },
    },
    watch:{
     question: 
        {
            handler(val){
            // do stuff
                //console.log(val)
            },
            deep: true
        }
    },
    mounted(){
        //console.log(this.question)
    },
    methods:{
    }

}
</script>
<template>
    <div>
        <loading :active.sync="isLoading" 
            :can-cancel="false" 
            :color="'#aa945b'"
            :is-full-page="fullPage">
            </loading>
        <CRow>
            <CCol sm="8">
                <CCard>
                     <CCardHeader>
                      <b>Question</b>
                    </CCardHeader>
                    <CCardBody v-if="question.selectedQuestion">
                        <multipleChoice  v-if="question.selectedQuestion.question_type == 'multiple_choice_question'" :question="question.selectedQuestion"></multipleChoice>
                        <multipleBlanks  v-else-if="question.selectedQuestion.question_type == 'fill_in_multiple_blanks_question'" :question="question.selectedQuestion"></multipleBlanks>
                        <multipleAnswers  v-else-if="question.selectedQuestion.question_type == 'multiple_answers_question'" :question="question.selectedQuestion"></multipleAnswers>
                        <matching  v-else-if="question.selectedQuestion.question_type == 'matching_question'" :question="question.selectedQuestion"></matching>
                        <shortAnswer  v-else-if="question.selectedQuestion.question_type == 'short_answer_question'" :question="question.selectedQuestion"></shortAnswer>
                        <multipleDropDowns  v-else-if="question.selectedQuestion.question_type == 'multiple_dropdowns_question'" :question="question.selectedQuestion"></multipleDropDowns>
                        <trueFalse  v-else-if="question.selectedQuestion.question_type == 'true_false_question'" :question="question.selectedQuestion"></trueFalse>
                        <numerical  v-else-if="question.selectedQuestion.question_type == 'numerical_question'" :question="question.selectedQuestion"></numerical>
                    </CCardBody>

                </CCard>
            </CCol>
            <CCol sm="4">
                <CCard>
                    <CCardHeader>
                      <b>Tags</b>
                    </CCardHeader>
                    <CCardBody>
                        <template v-for="(tag, index) in tag.data" class="mt-2">
                            <div v-if="tag.type == 'Tag'">
                                <label for="tag-name"><b>{{tag.name}}</b></label>
                                <multiselect
                                        v-if="question.selectedQuestion.tags"
                                        v-model="question.selectedQuestion.tags[tag.id]"
                                        tag-placeholder="Add this as new tag"
                                        placeholder="Search or add a tag"
                                        label="name"
                                        track-by="id"
                                        text-field="name"
                                        :options="tag.tag_items"
                                        :multiple="true"
                                        :taggable="true"
                                        :hide-selected="true"
                                        :show-no-results="false"
                                        :show-labels="false"
                                    >
                                    <template slot="tag" slot-scope="{ option, remove }">
                                        <span class="multiselect__tag" v-bind:class="{ 'auto-tagging-tag': option.migrated == 1}">
                                            <span>{{ option.name }}</span>
                                            <i
                                            aria-hidden="true"
                                            tabindex="1"
                                            @click="remove(option)"
                                            class="multiselect__tag-icon"
                                            ></i>
                                        </span>
                                    </template>
                                    <template slot="option" slot-scope="props">
                                        <span>{{props.option.name}}</span>
                                        <font-awesome-icon v-if="props.option.migrated == '1'" icon="tag"/>
                                    </template>
                                        <span slot="noResult">Oops! No elements found. Consider changing the search query.</span>
                                </multiselect>
                            </div>                                
                        </template>
                    </CCardBody>
                    <CCardFooter>
                         <CButton class="save-tag-button" @click="saveTagging">
                              Save Tagging
                          </CButton>
                    </CCardFooter>
                </CCard>
            </CCol>
        </CRow>

    </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css'; 
import { mapState } from "vuex";

// Components to display different Question Type
import multipleChoice from './multipleChoice';
import multipleBlanks from './multipleBlanks';
import multipleAnswers from './multipleAnswers';
import matching from './matching';
import shortAnswer from './shortAnswer';
import multipleDropDowns from './multipleDropDowns';
import trueFalse from './trueFalse';
import numerical from './numerical';


export default {
    middleware: "auth",
    name:'Question',
    data(){
        return{
            fullPage:true,
            isLoading:false
        }
    },
    components:{
        multipleChoice,
        multipleBlanks,
        multipleAnswers,
        matching,
        shortAnswer,
        multipleDropDowns,
        trueFalse,
        numerical
    },
    computed: {
        ...mapState(["question"]),
        ...mapState(["tag"]),
        filteredTagData: function() {
            return _.pickBy(this.tag.data, function(u) {
                return u.type == "Tag";
            });
        }
    },
    async mounted(){
        this.isLoading = true;
        await this.fetchSelectedQuestion();
        await this.$store.dispatch("tag/fetchTags", false);
        this.isLoading = false;
    },
    methods:{
        async fetchSelectedQuestion() {
            await this.$store.dispatch(
                "question/fetchSelectedQuestion",
                this.$route.params.id
            );
        },
        async saveTagging() {
            this.isLoading = true;
            await this.$store.dispatch("question/updateSelectedQuestion", {
                id: this.$route.params.id,
                tag_items: this.question.selectedQuestion.tags
            })
            .then(() => {
                this.isLoading = false;
                this.$router.go(-1);
            })
            .catch(error => {
                if (error.response) {
                Vue.notify({
                    group: "notify",
                    type: "error",
                    text: error.response.data.error.message,
                    max: 5,
                    duration: 5000,
                    speed: 300
                });
                } else {
                    console.log("Problem submitting New Post", error);
                }
            });
            this.isLoading = false;
        },
    }
}
</script>
<style>
    .save-tag-button{
        background-color:#998542;
        color:white
    }
</style>
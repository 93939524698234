<template>
    <div>
         <span v-html="getContent(question.question_text)"></span>
            <div class="mt-4">
                <input type="text" name="fill_in_blank" style="max-width: 180px; height:25px">
            </div>
            <div style="margin-top:10px">
                <div style="color:green; font-weight:bold">Correct Answer:</div>
                <div v-for="(answer, k) in question.answers" :key="k">
                    <span v-if="answer.weight == 100">{{k+ 1}}. {{answer.text}}</span>
                </div>
            </div>
    </div>
</template>
<script>
export default {
    name:'short-answer',
    props:{
        question: {
            type: Object,
            required: true,
            default: function () {
                return { id: null }
            }
        },
    },
    watch:{
     question: 
        {
            handler(val){
            // do stuff
                //console.log(val)
            },
            deep: true
        }
    },
    mounted(){
        //console.log(this.question)
    },
    methods:{
    }

}
</script>
<template>
    <div>
        <span v-html="getContent(question.question_text)"></span>
        <div :key="i" v-for="(answer_option, i) in question.answers" style="  border-top: 1px solid #d8d8d8;fonts-size:10px;">
            <input type="radio"  name="profileImg" :value="answer_option.id"> 
            {{answer_option.text}}
        </div>
        <div style="margin-top:10px">
            <div style="color:green; font-weight:bold">Correct Answer:</div>
            <div v-html="getCorrectAnswer(question)"></div>
        </div>
    </div>
</template>
<script>
export default {
    name:'true-false-question',
    props:{
        question: {
            type: Object,
            required: true,
            default: function () {
                return { id: null }
            }
        },
    },
    watch:{
     question: 
        {
            handler(val){
            // do stuff
                //console.log(val)
            },
            deep: true
        }
    },
    mounted(){
        //console.log(this.question)
    },
    methods:{

    }

}
</script>
<template>
    <div>
        <div v-html="getContent(question.question_text)"></div>
        <span><b>Answer Options:</b></span>
                <div :key="answer_option.id" v-for="(answer_option, i) in question.answers" style="  border-top: 1px solid #d8d8d8;fonts-size:10px;">
                     <input type="radio"  name="profileImg" :value="answer_option.id"> 
                     <span v-if="answer_option.html" v-html="getContent(answer_option.html)"></span>
                      <span v-else v-html="getContent(answer_option.text)"></span>
                     <!--{{answer_option.id}}-->
                  </div>                
                  <div style="margin-top:10px">
                      <div style="color:green; font-weight:bold">Correct Answer:</div>
                      <div v-html="getCorrectAnswer(question)"></div>
                  </div>

    </div>
</template>
<script>
export default {
    name:'multipleChoice',
    props:{
        question: {
            type: Object,
            required: true,
            default: function () {
                return { id: null }
            }
        },
    },
    watch:{
     question: 
        {
            handler(val){
            // do stuff
               // console.log(val)
            },
            deep: true
        }
    },
    mounted(){
        //console.log(this.question)
    },
    methods:{}

}
</script>